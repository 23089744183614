.details {
    display: flex;
    gap: 2rem;
}

.Request {
    &__bidResponsesTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    &__bidList {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
        margin-top: 10px;
    }

    &__bidCard {
        background-color: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        cursor: pointer;
        padding: 16px;
        transition: box-shadow 0.3s ease;

        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
    }

    &__bidCardContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__bidNote {
        font-size: 14px;
        color: #333;
        margin-bottom: 8px;
    }
    &__bidNoteHead {
        font-size: 16px;
        color: #333;
        margin-bottom: 8px;
        font-weight: bold;
    }

    &__bidExpiration {
        font-size: 14px;
        color: #555;
    }

    &__errorText {
        font-size: 14px;
        color: red;
    }

    &__Button {
        margin-left: 10px;
        min-width: auto;
        padding: 6px 12px;
    }
    &__bidResponsesTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    &__buttonWrapper {
        &:not(:last-child) {
            margin-right: 1rem;
        }
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    &__Popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    &__Popup__content {
        // background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        // box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        width: 80%;
        max-height: 80%;
        overflow-y: auto;
    }

    .popup__title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    .popup__detail {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .popup__close {
        margin-top: 16px;
        text-align: center;
    }

    .popup__close button {
        padding: 10px 20px;
        border: none;
        background-color: #333;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin: 2.4rem 0;
    }

    &__rfqId {
        font-size: 2rem;
        font-weight: 600;
    }

    &__descriptionTitle {
        font-size: 1.8rem;
        // font-weight: 500;
    }

    &__text {
        display: flex;
        justify-content: space-between;
        font-size: 1.6rem;
        color: grey;
    }
    &__text span:nth-child(2) {
        color: black;
        font-size: 1.6rem;
        text-transform: capitalize;
    }

    &__organizationDetails {
        background-color: #231709;
        padding: 1.4rem 2rem;
        color: white;
        border-radius: 10px 10px 0 0;
    }

    &__organizationContents {
        padding: 1.4rem 2rem;
    }
    &__organizationName {
        font-size: 1.6rem;
        font-weight: 600;
    }

    &__formWrapper {
        margin-top: 2rem;
    }
}
.closeButton {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #00b976;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
        background-color: #02794d;
    }
}

.RFQPopupContent {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin: 0 auto;

    .popup__title {
        font-size: 1.6rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
    }

    .popup__detail {
        margin-bottom: 8px;
        font-size: 1.2rem;
        color: #666;

        span {
            font-weight: bold;
            color: #333;
            margin-right: 6px;
        }
    }

    .closeButton {
        margin-top: 20px;
        padding: 10px 20px;
        border: none;
        background-color: #00b976;
        color: #fff;
        border-radius: 6px;
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: bold;
        transition: background-color 0.3s;

        &:hover {
            background-color: #02794d;
        }
    }
}

.Request__attachments {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: center;
}

.Request__attachmentTitle {
    font-weight: bold;
}

.Request__attachmentLink {
    display: flex;
    justify-content: flex-end;

    a {
        color: blue;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.Request__focalPoints {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .Request__focalUser {
        font-weight: bold;
    }
}

.Request__bidCardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}
.Card {
    border-radius: 10px;
    width: 100%;
    background-color: white;
    border: 1px solid #e3e3e3;
    margin-top: 20px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
}
