@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

.AddProduct {
    &__title {
        margin-top: 0rem;
        font-size: 1.8rem;
        font-weight: bold;
        margin: 2rem 0;
    }

    &__inputField {
        font-size: 1.4rem;
    }

    &__imageWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        grid-template-rows: repeat(auto-fit, 20rem);
        grid-auto-flow: dense;
        grid-gap: 0.3rem;
    }

    &__image {
        height: 20rem;
        width: 20rem;
        background-color: green;
    }
    &__cancelButton {
        background-color: white;
        color: black;
        border: 2px solid darkgray;
    }
    &__cancelButton:hover {
        background-color: #f2f2f2;
    }
}
.Btn__Container {
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
}
