.FilterContainer {
    display: flex;
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    gap: 1rem;
    color: #636363;
    width: fit-content;
}
.Tab {
    padding: 3rem;
    height: fit-content;
    &__Items {
        font-size: 1.4rem;
        cursor: pointer;
        padding: 0.7rem 0.9rem;
    }

    &__Selected {
        background-color: #47e696;
        // border-bottom: 4px solid #47e696;
        font-weight: 500;
        color: black;
        margin: 2px;
        border-radius: 10px;
    }
}
